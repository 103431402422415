#profile {
  .main-content {
    display: flex;
    flex-direction: column;
    padding-left: 32px;
    padding-bottom: 32px;
  }

  .no-plan-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 30px;
  }

  #paymentplans {
    scale: 0.9;
    margin-left: -45px;
    margin-top: -15px;

    @media (width <= 1450px) {
      scale: 0.85;
      margin-left: -75px;
      margin-top: -55px;
    }

    @media (width <= 1350px) {
      scale: 0.82;
      margin-left: -90px;
      margin-top: -60px;
    } 

   /*  @media (width <= 1280px) {
      scale: 0.7;
      margin-left: -130px;
      margin-top: -85px;
    } */
  }

  header {
    width: 90%;
    //max-width: 800px;
  }

  .hello-text {
    align-self: flex-start;

    span {
      text-transform: capitalize;
    }
  }

  .update-profile-btn {
    margin-top: 22px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media (width <= 840px) {
      flex-direction: column;
    }
  }

  .update-btn {
    padding-left: 12px;

    @media (width <= 840px) {
      padding-left: 0px;
      padding-bottom: 12px;
    }
  }

  form,
  .box-password {
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }

  .profile-data-container {
    display: flex;
    flex-wrap: wrap;
    gap: 29px;
    //max-width: 1200px;

    & > div {
      width: calc(50% - 23px);
      @media (width <= 860px) {
        width: 100%;
      }
    }

    div:nth-child(2) {
      input {
        color: #0249fb;
      }
    }

    .first-row,
    .second-row {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    @media (width <= 860px) {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  .box-profile,
  .box-password {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 18px 42px 18px 42px;
    //width: calc(100% - 52px);
    width: 94%;
    max-width: 1200px;
    //height: 200px;
    //border: 1px solid grey;
    //background-color: #f1efec;
    background-color: white;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    border-radius: 11px;

    @media (width <= 510px) {
      padding: 18px 16px;
    }
  }

  .text-profile,
  .text-password {
    margin-bottom: 36px;
  }

  .first-button-save {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 36px;
  }

  .reset-pwds-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: end;

    button {
      width: 40%;
      @media (width <= 860px) {
        width: 100%;
      }
    }

    @media (width <= 860px) {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 18px;
    }
  }

  @media only screen and (max-width: 700px) {
    .profile-data-container,
    .change-password-container {
      flex-direction: column;
    }
  }

  .plan-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 22px;

    .plan-box {
      padding: 18px 42px 18px 42px;
      width: 94%;
      max-width: 1200px;
      background-color: white;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
      border-radius: 11px;
      gap: 15px;
      display: flex;
      flex-direction: column;
      > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #c2c2c2;
        font-size: 13px;
        font-weight: 600;
        padding-bottom: 6px;
      }

      > div:last-child, > div:first-child {
        border-bottom: transparent;
        padding-bottom: 0px;
      }

      > div:first-child {
        margin-bottom: 5px;
        font-size: 15px;
        font-weight: bolder;
      }
    }


    .complete-package {
      border-bottom: transparent;
      font-weight: 600;
      font-size: 14px;
      padding-bottom: 12px;
    }
  }

  .subscription {
    font-size: 15px;
    font-weight: 600;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .subscription-text {
    margin-top: 34px;
    margin-bottom: 12px;
    margin-left: 5px;
  }
}

.confirm-parental-modal {
  .modal-body {
    display: flex;
    flex-direction: column;
    //gap: 20px;
    padding: 40px 50px 15px 50px;
  }
  .back-btn {
    height: 40px;
    width: 90px;

    font-size: 0.95em;
    font-weight: 900;
  }

  .custom-success-footer {
    margin-top: 30px;
  }
}
