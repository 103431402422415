#exams-table {
  .table-content {
    padding-left: 30px;
    padding-right: 30px;
    //padding-top: 35px;
    //max-width: 1350px;
    background: white;
    border-radius: 11px;

    @media (width <= 780px) {
      padding-right: 10px;
      padding-left: 10px;
    }

  }

  .bulk-checkbox {
    margin-right: 8px;
  }

  .copy-id {
    font-size: 11px;
    color:#0249fb;
    cursor: pointer;
    margin-left: 10px;
  }

  .rank,
  .difficulty {
    @media (width <= 750px) {
      display: none;
    }
  }

  .title-score, .action, .score{
    @media (width <= 680px) {
      display: none;
    }

  }

  .title-score {
    display: flex;
    flex-direction: column;
    padding-top: 0;

    .sc {
      margin-left: 13px;
    }
    span {
      translate: 0px 7px;
    }
  }

  tr th {
    text-transform: uppercase;
  }

  th {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 13px;
    color: #b5b5c3;
    border-bottom: 1px solid #e5eaee;
  }

  td {
    border-bottom: 1px solid #e5eaee;
    padding: 17px 10px;
    vertical-align: middle;
    //ext-align: end;

    @media (width <= 750px) {
      padding: 17px 12px;
    }

    @media (width <= 540px) {
      padding: 17px 6px;
    }

  }

  th {
    padding: 16px 10px;
    //text-align: end;
  }

  .th-name {
    text-align: initial;
  }
  .action {
    text-align: end;
    padding: 16px 10px 16px 10px;
  }

  .exam-name-td {
    min-width: 110px;
    font-size: 13px;
    color: black;

    

    div:nth-child(1) {
      font-weight: 600;
    }

    div:nth-child(2) {
      display: flex;
      //gap: 10px;
      align-items: baseline;
      margin-top: 4px;

      @media (width <= 540px) {
        gap: 7px;
        font-size: 11px;
      }

      @media (width <= 460px) {
        margin-top: 0px;
      }

      

    }
  }

  .activity-date {
    font-family: 'Poppins';
    font-weight: 300;
    font-size: 12px;
    color: #b5b5c3;
  }

  .title-container {
    display: flex;
    //justify-content: space-between;
    margin-left: 9px;
    //margin-right: 58px;
    gap: 8px;
    align-items: center;

    img {
      height: 36px;
    }
  }
  .exam-list-title {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 21px;
    display: flex;
    justify-content: space-between;
  }

  .input-exam {
    border: none;
    background: #f3f6f9;
    border-radius: 9px;
    height: 36px;
    width: 180px;
  }

  .start-element {
    display: flex;
    align-items: center;
    justify-content: end;
    border: none;
    background: none;
    cursor: pointer;
    //gap: 8px;
    margin-right: 10px;

    @media (width <= 750px) {
      margin-right: 0px;
    }


  }

  .name-first-row {
    display: flex;
    align-items: center;
    @media (width <= 540px) {
      font-size: 12px;
    }

  }

  .text-new {
    font-family: 'Poppins';
    font-size: 10px;
    font-weight: 700;
    color: white;
    border-radius: 7px;
    padding: 2px 10px;
    margin-left: 10px;
    background-color: #20d489;

    &.offline {
      background: red;
    }

    &.online,
    &.offline {
      font-size: 11px;
      cursor: pointer;
    }

    &.draft {
      background: brown;
    }
  }

  .text-expected {
    border-radius: 7px;
    padding: 2px 10px;
    margin-left: 10px;
    background-color: #20d489;
    font-size: 12px;
    color: white;
    font-weight: 500;

    &.orange {
      background-color: #ff6e00;
    }
  }

  .retries {
    display: flex;
    //justify-content: center;
    margin-left: 10px;
    span {
      font-family: 'Poppins';
      font-size: 14px;
      font-weight: 600;
      width: 29px;
      justify-content: center;
      display: flex;
      color: #0249fb;
      padding: 2px 8px;
      border: 1.5px solid #0249fb;
      border-radius: 50%;
    }
  }

  .btn-primary {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 7px 17px;
    font-size: 13px;

    @media (width <= 750px) {
      padding: 7px 7px;
      gap: 5px;
    }

    @media (width <= 540px) {
      font-size: 11px;
    }

    
  }

  .text-difficulty {
    display: flex;
    gap: 4px;
    color: #ffc700;
    scale: 0.9;
    //justify-content: end;

    .faded-star {
      color: #b5b5c3;
    }
  }

  .text-rank,
  .score {
    font-family: 'Poppins';
    font-size: 13px;
    font-weight: 600;
    color: #b5b5c3;

  }

  .action-icons {
    display: flex;
    gap: 10px;
    //justify-content: center;
    justify-content: end;

    img {
      //width: 13px;
    }

    @media (width < 1024px) {
      flex-direction: column;
      align-items: center;
    }

    .text-action {
      text-align: end;
    }

    .comment-icon {
      color: #0249fb;
    }

    > div {
      padding: 4px 6px;
      background-color: #f3f6f9;
      border-radius: 6px;
      cursor: pointer;
    }

    .lock-div {
      @media (width< 1024px) {
        padding: 2px 4px;
        img {
          width: 15px;
          //padding-left: 2px;
          translate: 1px;
        }
      }
    }
  }

  .time {
    display: flex;
    gap: 8px;
  }

  .clock-icon, .retries-icon-image{
    @media (width < 460px) {
      display: none;
    }



  }


  .caption {
    display: flex;
    gap: 12px;
    align-items: center;
  }

  .text-questions {
    font-size: 13px;
    font-weight: 400;

    @media (width <= 540px) {
      font-size: 11px;
    }

  }
}

.cover-modal {
  .modal-body {
    margin: 0;
    padding: 0;

    @media (width <= 900px) {
    > div {
      overflow: auto;

      img {
        height: 100%;
        max-width: fit-content;
      }
    }
    }
  }
  .modal-content {
    width: 85vw;
  }

  img {
    max-width: 82vw;
    max-height: 80vh;
    object-fit: contain;
    @media (height <= 500px) {
      max-height: 72vh;
    }
  }

  .custom-success-footer {
    padding-right: 25px;
  }

  .loader-container {
    height: 100%;
    min-height: 70vh;
    top: 0;
    left: 0;
    z-index: 999;
    width: 80vw;
    position: initial;
    background-color: white;
    opacity: 0.55;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .lds-ellipsis div {
    background: black;
  }

  
}
