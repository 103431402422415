#exams-management {
  display: flex;
  flex-direction: column;
  padding: 1px 32px;

  .add-new-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
    gap: 12px;
  }
  .bulkchanges-container {
    display: flex;
    //gap: 16px;
    width: calc(100% - 110px);
    padding: 3px 16px;
    translate: 10px 10px;
    margin-left: 16px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
    position: fixed;
    right: 15px;
    background-color: #cabdbd;
    border-radius: 14px;
    bottom: 20px;

    .btn {
      scale: 0.82;
    }
  }

}
